import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
const TextIta = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4} style={{ textAlign: "center" }}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Stack spacing={2}>
            <Typography variant="h6" sx={{ textAlign: "left", m: 1 }}>
              How it works
            </Typography>
            <Typography sx={{ textAlign: "left", m: 1 }}>
              Upload your DDD files.
            </Typography>
            <Typography sx={{ textAlign: "left", m: 1 }}>
              A PDF version will be created in few minutes.
            </Typography>
            <Typography sx={{ textAlign: "left", m: 1 }}>
              If something goes wrong you will be notified.
            </Typography>
            <Typography sx={{ textAlign: "left", m: 1 }}>
              Push the button to see the PDF.
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={4} style={{ textAlign: "center" }}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Stack spacing={2}>
            <Typography variant="h6" sx={{ textAlign: "left", m: 1 }}>
              Read here
            </Typography>
            <Typography sx={{ textAlign: "left", m: 1 }}>
              At the moment we can decode only VU generation 1 DDD files.
            </Typography>
            <Typography sx={{ textAlign: "left", m: 1 }}>
              Let's us to know about any problems or error you encounter.
            </Typography>
            <Typography sx={{ textAlign: "left", m: 1 }}>
              Your data will be deleted after the download.
            </Typography>
            <Typography sx={{ textAlign: "left", m: 1 }}>
              We will analyze your data only in case of errors to improve the
              service
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={4} style={{ textAlign: "center" }}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Stack spacing={2}>
            <Typography variant="h6" sx={{ textAlign: "left", m: 1 }}>
              Warnings
            </Typography>
            <Typography sx={{ textAlign: "left", m: 1 }}>
              The service is in an experimental stage
            </Typography>
            <Typography sx={{ textAlign: "left", m: 1 }}>
              The infrastructure behind the service has been improved, anyway be
              patient
            </Typography>
            <Typography sx={{ textAlign: "left", m: 1 }}>
              Give your support and visit our sponsors
            </Typography>
            <Typography sx={{ textAlign: "left", m: 1 }}>
              <Button
                href="https://www.sramp.com/?page_id=198"
                target="_blank"
                variant="contained"
              >
                Send a message
              </Button>
            </Typography>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TextIta;
