import "./App.css";
import React, { useState } from "react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import UploadFile from "./UploadFile.jsx";
import TextIta from "./TextIta.jsx";
import TextEng from "./TextEng.jsx";
import engFlag from "./assets/eng.png";
import itaFlag from "./assets/ita.png";
import AdSense from "react-adsense";

function App() {
  const [language, setLanguage] = useState(0);

  const handleChangeLanguage = (event) => {
    setLanguage(event.target.value);
  };

  return (
    <div className="App">
      <header className="App-header">
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={3} style={{ textAlign: "left" }}>
              {" "}
            </Grid>
            <Grid item xs={6} style={{ textAlign: "center" }}>
              {" "}
              <Typography variant="h3">Tacho 2024</Typography>
            </Grid>
            <Grid item xs={3} style={{ textAlign: "rifght" }}>
              <Select
                sx={{
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={language}
                label="Language"
                onChange={handleChangeLanguage}
                disableUnderline="true"
              >
                <MenuItem value={0}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img src={itaFlag} alt="italiano" />
                  </div>
                </MenuItem>
                <MenuItem value={1}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img src={engFlag} alt="english" />
                  </div>
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Box>
        {language === 0 && <TextIta />}
        {language === 1 && <TextEng />}
        <p>
          <UploadFile language={language} />
        </p>
      </header>
      <AdSense.Google
        client="ca-pub-8850793267495641"
        slot="9360625678"
        style={{ display: "block" }}
        format="auto"
        responsive="true"
      />
    </div>
  );
}

export default App;
