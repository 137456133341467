import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
const TextIta = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4} style={{ textAlign: "center" }}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Stack spacing={2}>
            <Typography variant="h6" sx={{ textAlign: "left", m: 1 }}>
              Come funziona
            </Typography>
            <Typography sx={{ textAlign: "left", m: 1 }}>
              Caricate il vostro file DDD
            </Typography>
            <Typography sx={{ textAlign: "left", m: 1 }}>
              Una versione PDF verrà create in pochi minuti
            </Typography>
            <Typography sx={{ textAlign: "left", m: 1 }}>
              Se qualcosa andasse storto un messaggio vi avvertirà
            </Typography>
            <Typography sx={{ textAlign: "left", m: 1 }}>
              Premere il bottone per visualizzare il PDF
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={4} style={{ textAlign: "center" }}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Stack spacing={2}>
            <Typography variant="h6" sx={{ textAlign: "left", m: 1 }}>
              Leggi qui
            </Typography>
            <Typography sx={{ textAlign: "left", m: 1 }}>
              Per il momento il servizio è disponibile solo per i file DDD VU
              generazione 1
            </Typography>
            <Typography sx={{ textAlign: "left", m: 1 }}>
              Fateci sapere per ogni problema e errore che riscontrate
            </Typography>
            <Typography sx={{ textAlign: "left", m: 1 }}>
              I vostri dati verranno cancellati dopo il download.
            </Typography>
            <Typography sx={{ textAlign: "left", m: 1 }}>
              I vostri dati verranno analizzati in caso di errore allo scopo di
              migliorare il servizio.
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={4} style={{ textAlign: "center" }}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Stack spacing={2}>
            <Typography variant="h6" sx={{ textAlign: "left", m: 1 }}>
              Attenzione
            </Typography>
            <Typography sx={{ textAlign: "left", m: 1 }}>
              Il servizio é in fase sperimentale.
            </Typography>
            <Typography sx={{ textAlign: "left", m: 1 }}>
              L'infrastruttura dietro il serizio è stata migliorata, ma siate
              pazienti.
            </Typography>
            <Typography sx={{ textAlign: "left", m: 1 }}>
              Date il vostro supporto e visitate i nostri partner
            </Typography>
            <Typography sx={{ textAlign: "left", m: 1 }}>
              <Button
                href="https://www.sramp.com/?page_id=198"
                target="_blank"
                variant="contained"
              >
                Invia un messaggio
              </Button>
            </Typography>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TextIta;
