import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const UploadFile = ({ language }) => {
  const [selectedFile, setSelectedFile] = useState("");

  const [pdfLink, setPdfLink] = useState("#");
  const [mssg, setMssg] = useState("");
  const [polling, setPolling] = useState(false);
  const [uid, setUid] = useState("");
  const pollingRef = useRef(null);

  const [buttonState, setButtonState] = useState("");

  useEffect(() => {
    if (polling) {
      pollingRef.current = setInterval(getLinkMssg, 10000);
    } else {
      clearInterval(pollingRef.current);
      pollingRef.current = null;
    }
  }, [polling]);

  const handleFileUpload = (event) => {
    setSelectedFile(event.target.files[0]);
    console.log(event.target.files[0]);
    setPolling(false);
    setPdfLink("#");
    setMssg("");
  };

  const getLinkMssg = async () => {
    console.log("getLinkMssg" + uid);
    const responseLink = await axios.get("https://api.sramp.com/link/" + uid);
    if (responseLink.data.length > 0) {
      setPdfLink(responseLink.data);
      setButtonState(language === 0 ? "Apri il PDF" : "Open PDF");
      setPolling(false);
      setMssg("");
    }
    const responseMssg = await axios.get("https://api.sramp.com/mssg/" + uid);
    if (responseMssg.data.length > 0) {
      setMssg(responseMssg.data);
      setPolling(false);
    }
  };

  const createUID = (lang) => {
    return lang + Math.random().toString(16).slice(8);
  };

  const handleUpload = () => {
    const headers = { "Content-Type": "application/octet-stream" };

    const idUnique = createUID(language);
    setUid(idUnique);
    const formData = new FormData();
    formData.append("upload", selectedFile);
    formData.append("id_sramp", idUnique);
    axios
      .post("https://api.sramp.com/upload", formData, {
        headers: headers,
      })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    console.log("startpolling");
    setPolling(true);
    setPdfLink("#");
    setMssg(language === 0 ? "Attendere" : "Wait");
  };

  return (
    <div>
      <h3>{language === 0 ? "Carica il file .DDD" : "Upload File .DDD"}</h3>
      <input
        type="file"
        style={{ width: "300px" }}
        onChange={handleFileUpload}
      />

      <button onClick={handleUpload} disabled={selectedFile == ""}>
        {language === 0 ? "Carica" : "Upload"}
      </button>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="10vh"
      >
        {pdfLink !== "#" && (
          <Button href={pdfLink} target="_blank" variant="contained">
            {buttonState}
          </Button>
        )}
        {mssg != "" && (
          <Typography sx={{ textAlign: "left", m: 1 }}>{mssg}</Typography>
        )}
      </Box>
      {polling && <CircularProgress />}
    </div>
  );
};

export default UploadFile;
